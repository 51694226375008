import React, { useState, useEffect } from "react";
import { Text } from '@fluentui/react-northstar'
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest, sitesRequestSharePoint, sitesRequestGraph } from "../authConfig";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Label } from '@fluentui/react-northstar'
import { List, Status, Box } from '@fluentui/react-northstar'
import {
    callGraphSiteSettingsItems,
    callSharePointPermisisons,
    callSharePointPermissionsChange,
    getSharePointPermissions,
    callSharePointPlans
} from "../graph";
import {
    Link, MessageBar,
    MessageBarType
} from '@fluentui/react';
import {
    IconButton
} from '@fluentui/react/lib/Button';
import {
    OpenOutsideIcon,
    FilesTxtIcon,
    InfoIcon,
    TableIcon,
    ShieldKeyholeIcon,
    FilesZipIcon,
    AddIcon,
    CloseIcon,
    ArrowSortIcon,
    DownloadIcon,
    ExcelIcon,
    FilesUploadIcon,
    CheckmarkCircleIcon,
    EditIcon,
    ArrowSyncIcon,
    ApprovalsAppbarIcon,
    SettingsIcon,
    ListIcon,
    ExpandIcon,
    CollapseIcon
} from '@fluentui/react-icons-northstar'
import jwtDecode from 'jwt-decode';
import { Button, Dialog } from '@fluentui/react-northstar'
//import { useBoolean } from '@fluentui/react-hooks';
import { Avatar } from '@fluentui/react-northstar'
import moment from 'moment'
import { Card, Flex } from '@fluentui/react-northstar'
import { Image } from '@fluentui/react-northstar'
import { useParams } from "react-router-dom"
import { useNavigate, matchRoutes, useLocation } from 'react-router-dom';
import PeoplePicker from "./PeoplePicker";


export const TeamData = (props) => {
    const navigate = useNavigate();
    const { id } = useParams()
    const location = useLocation()
    const [permSettingsFile, setPermSettingsFile] = useState(null);
    const [planSettingsFile, setPlanSettingsFile] = useState(null);
    const [permissionsToChange, setPermissionsToChange] = useState(null);
    const [loadingChanges, setLoadingChanges] = useState(false);
    const { instance, accounts } = useMsal();
    //const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    useEffect(() => {
        document.addEventListener("visibilitychange", function () {
            if (!document.hidden) {
                RequestSiteSettingsItems()
                //acquireTokenPopupAndCallPermissionsChange()
            }
        });
        console.log(location)

    }, [])

    useEffect(() => {
        setPlanSettingsFile(null)
        setPermSettingsFile(null)
        setPermissionsToChange(null)
    }, [props])

    moment.locale();

    useEffect(() => {
        setPermSettingsFile(null)
        RequestSiteSettingsItems()
    }, [props.data]);

    function RequestSiteSettingsItems() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        setPermissionsToChange(null)
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            console.log(response)
            callGraphSiteSettingsItems(props.data?.id, response.accessToken)
                .then(response => {
                    console.log(response?.value)
                    if (response?.value) {
                        response?.value?.forEach(obj => {
                            if (obj.webUrl.includes("permission_matrix.xlsx")) {
                                obj.deployed = (obj?.fields?._CheckinComment == 'deployed' || obj?.fields?._CheckinComment == 'report')
                                setPermSettingsFile(obj)
                            }
                            if (obj.webUrl.includes("schema_input.json")) {
                                obj.deployed = (obj?.fields?._CheckinComment == 'deployed' || obj?.fields?._CheckinComment == 'report')
                                setPlanSettingsFile(obj)
                            }
                        })
                    } else {
                        setPermSettingsFile(null)
                        setPlanSettingsFile(null)
                    }
                });
        });

    }
    var domain = ""
    try {
        domain = RemoveLastTwoDirectories(props.data?.webUrl);
    } catch (e) { console.log(e) }

    var requestObjPermissions = {
        scopes: ["Sites.FullControl.All"],
        account: accounts[0]
    };
    var requestObjPermissionsSharePoint = {
        scopes: [domain + "/.default"],
        account: accounts[0]
    };
    function acquireTokenPopupAndCallPermissions() {
        instance.acquireTokenSilent(requestObjPermissionsSharePoint).then(function (tokenResponse) {
            const claims = jwtDecode(tokenResponse.accessToken);
            if (!claims.scp.includes("Sites.FullControl.All")) {
                instance.acquireTokenPopup(requestObjPermissions)
                    .then(function (tokenResponse) {
                        instance.acquireTokenPopup(requestObjPermissionsSharePoint)
                            .then(function (tokenResponse) {
                                callSharePointPermisisons(props.data?.webUrl, tokenResponse.accessToken)
                                    .then(response => { RequestSiteSettingsItems() });
                            }).catch(error => {
                                console.log(error);
                            });
                    }).catch(error => {
                        console.log(error);
                    });
            } else {
                callSharePointPermisisons(props.data?.webUrl, tokenResponse.accessToken)
                    .then(response => { RequestSiteSettingsItems() });
            }
        }).catch(function (error) {
            console.log(error)
        });
    }

    function acquireTokenPopupAndGetPermissions() {
        instance.acquireTokenSilent(requestObjPermissionsSharePoint).then(function (tokenResponse) {
            const claims = jwtDecode(tokenResponse.accessToken);
            if (!claims.scp.includes("Sites.FullControl.All")) {
                instance.acquireTokenPopup(requestObjPermissions)
                    .then(function (tokenResponse) {
                        instance.acquireTokenPopup(requestObjPermissionsSharePoint)
                            .then(function (tokenResponse) {
                                getSharePointPermissions(props.data?.webUrl, tokenResponse.accessToken)
                                    .then(response => { RequestSiteSettingsItems() });
                            }).catch(error => {
                                console.log(error);
                            });
                    }).catch(error => {
                        console.log(error);
                    });
            } else {
                getSharePointPermissions(props.data?.webUrl, tokenResponse.accessToken)
                    .then(response => { RequestSiteSettingsItems() });
            }
        }).catch(function (error) {
            console.log(error)
        });
    }

    function acquireTokenPopupAndCallPermissionsChange() {
        setLoadingChanges(true)
        instance.acquireTokenSilent(requestObjPermissionsSharePoint).then(function (tokenResponse) {
            console.log(tokenResponse.accessToken)
            callSharePointPermissionsChange(props.data?.webUrl, tokenResponse.accessToken).then(response => {
                const map1 = response.map((x, i) => {
                    const add = x[3] == 'add' || !x[1];
                    return {
                        key: i,
                        media: <Avatar
                            icon={x[1] ? <ShieldKeyholeIcon /> : <FilesZipIcon />}
                            status={{
                                color: add ? "green" : "red",
                                icon: add ? <AddIcon /> : <CloseIcon />,
                                title: add ? "add" : "remove",
                            }}
                        />,
                        content: {
                            content: x[2],
                            truncate: true
                        },
                        contentMedia: x[1],
                        header: {
                            content: x[0],
                            truncate: true,
                            styles: {
                                fontSize: 'smaller',
                                fontWeight: '600'
                            }
                        }
                    }
                });
                setPermissionsToChange(map1)
                setLoadingChanges(false)
            });
        }).catch(function (error) {
            setLoadingChanges(false)
            console.log(error)
        });
    }

    var requestObjPlans = {
        scopes: [domain + "/.default"],
        account: accounts[0]
    };

    function acquireTokenAndCallPlans() {
        instance.acquireTokenSilent(requestObjPlans).then(function (tokenResponse) {
            callSharePointPlans(props.data?.webUrl, tokenResponse.accessToken)
                .then(() => RequestSiteSettingsItems())
                .catch(error => {
                    if (String(error).includes('UnauthorizedClientCredential')) {
                        window.open(`https://login.microsoftonline.com/${accounts[0]?.tenantId}/adminconsent?client_id=${process.env["REACT_APP_AAD_APP_CLIENT_ID"]}&scope=Sites.ReadWrite.All&redirect_uri=${process.env["REACT_APP_AAD_APP_REDIRECT_URI"]}&state={3}`)
                    }
                });
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                instance.acquireTokenPopup(requestObjPlans.account)
                    .then(function (tokenResponse) {
                        callSharePointPlans(props.data?.webUrl, tokenResponse.accessToken)
                            .then(() => RequestSiteSettingsItems())
                    }).catch(error => {
                        console.log(error);
                    });
            }
        });
    }

    return (
        <Flex column padding="padding.medium">
            <Flex gap="gap.large" vAlign="center" padding="padding.medium">
                <Text
                    content={props?.data?.displayName ?? "Seite nicht gefunden"}
                    disabled={!props?.data?.displayName}
                    weight="bold"
                    size="larger" />
                <Button
                    icon={location.pathname.includes('sharepoint') ? <CollapseIcon /> : <ExpandIcon outline />}
                    iconOnly
                    flat
                    onClick={() => navigate(location.pathname.includes('sharepoint') ? '/' : "sharepoint?webUrl=" + props.data?.webUrl)}
                    title={location.pathname.includes('sharepoint') ? "Alle Seiten anzeigen" : "Seite verwalten"}
                />
            </Flex>
            <Flex column gap="gap.medium" vAlign="center" padding="padding.medium">
                <Link target='_blank' href={props.data?.webUrl}>{props.data?.webUrl}</Link>
                <Text weight="light" content={props.data?.description} />
            </Flex>
            <div>
                <Card fluid ghost={false} >
                    <Card.Header>
                        <Avatar
                            styles={{ padding: '10px' }}
                            size="largest"
                            image="https://static2.sharepointonline.com/files/fabric/assets/item-types/16/spreadsheet.svg"
                            square
                        />
                        <br></br>
                        <Flex gap="gap.small">
                            <Flex column>
                                <Text content="Berechtigungen" weight="bold" />
                                <Text content="Alle Gruppen, Ordner und Rechte managen" size="small" />
                            </Flex>
                        </Flex>
                    </Card.Header>
                    <Card.Body>
                        <div>
                            <Button
                                icon={<DownloadIcon />}
                                onClick={acquireTokenPopupAndGetPermissions}
                                text
                                content="Aktuelle Rechtematrix generieren"
                            />
                            <br></br>
                            <Button
                                fluid
                                //text={webUrl+"https://TENANT.sharepoint.com/:w:/r/sites/SITE_COLLECTION/_layouts/15/Doc.aspx?sourcedoc={DOCUMENT_GUID}"}
                                icon={<ExcelIcon />}
                                onClick={() => window.open(getViewUrl(permSettingsFile, props.data?.webUrl))}
                                content="Rechtematrix bearbeiten"
                                disabled={!permSettingsFile}
                                title="Open" />
                            <br></br>
                            <br></br>
                            {permSettingsFile && permSettingsFile?.fields && !permissionsToChange && <Button
                                size="small"
                                styles={permSettingsFile ? {
                                    "color": permSettingsFile?.deployed ? "green" : "orange",
                                } : {}}
                                text
                                disabled={true}
                                icon={permSettingsFile?.deployed ? <CheckmarkCircleIcon /> : <ApprovalsAppbarIcon />}
                                content={permSettingsFile?.deployed ? "Aktualisiert" : "Neue Änderungen"}
                                flat
                                loading={false} />}
                            {permissionsToChange && <Dialog
                                cancelButton="Schließen"
                                text
                                content={{
                                    content: (
                                        <>
                                            <br></br>
                                            <List items={permissionsToChange} />
                                        </>
                                    )
                                }}
                                header="Änderungen in der Rechtematrix"
                                trigger={
                                    <Button
                                        size="small"
                                        disabled={!permSettingsFile}
                                        styles={{ "color": "orange" }}
                                        text
                                        icon={<ApprovalsAppbarIcon />}
                                        content={permissionsToChange.length + " neue Änderungen"} />
                                }
                            />}
                            <Text
                                styles={{ 'vertical-align': 'middle', paddingRight: '10px' }}
                                timestamp
                                content={permSettingsFile ? 'am ' + moment(permSettingsFile?.lastModifiedDateTime).format('LLL') : ''}
                                size="small" />
                            {permSettingsFile && permSettingsFile?.fields && !permSettingsFile?.deployed && <Button
                                primary
                                size="small"
                                onClick={permissionsToChange ? acquireTokenPopupAndCallPermissions : acquireTokenPopupAndCallPermissionsChange}
                                content={permissionsToChange ? "Speichern" : "Prüfen"}
                                flat />}
                        </div>
                    </Card.Body>
                </Card>
                <br></br>
                <Card fluid ghost={false} >
                    <Card.Header>
                        <Avatar
                            styles={{ padding: '10px' }}
                            size="largest"
                            image="https://static2.sharepointonline.com/files/fabric/assets/item-types/16/pdf.svg"
                            square
                        />
                        <br></br>
                        <Flex gap="gap.small">
                            <Flex column>
                                <Text content="Planmanagement" weight="bold" />
                                <Text content="Alle Gruppen, Ordner und Rechte managen" size="small" />
                            </Flex>
                        </Flex>
                    </Card.Header>
                    <Card.Body>
                        <div>
                            <Button
                                fluid
                                icon={<FilesTxtIcon />}
                                onClick={() => window.open(getJsonViewUrl(planSettingsFile, props.data?.webUrl))}
                                content="Plankodierung bearbeiten"
                                disabled={!planSettingsFile}
                                title="Open" />
                            <br></br>
                            <br></br>
                            {planSettingsFile && planSettingsFile?.fields && <Button
                                text
                                size="small"
                                styles={planSettingsFile ? {
                                    "color": planSettingsFile?.deployed ? "green" : "orange",
                                } : {}}
                                icon={planSettingsFile?.deployed ? <CheckmarkCircleIcon /> : <ApprovalsAppbarIcon />}
                                content={planSettingsFile && planSettingsFile?.deployed ? "Aktualisiert" : "Neue Änderungen"}
                                flat
                                disabled
                                loading={false} />}
                            <Text
                                styles={{ 'vertical-align': 'middle', paddingRight: '10px' }}
                                content={planSettingsFile ? 'am ' + moment(planSettingsFile?.lastModifiedDateTime).format('LLL') : ' '}
                                size="small" />
                            {planSettingsFile && planSettingsFile?.fields && !planSettingsFile?.deployed && <Button
                                primary
                                size="small"
                                onClick={acquireTokenAndCallPlans}
                                content="Speichern"
                                flat
                                loading={false} />}
                            {/* <MessageBar
                                    messageBarType={MessageBarType.info}
                                    isMultiline={false}
                                    truncated={true}
                                    overflowButtonAriaLabel="See more"
                                    styles={{ root: { background: 'unset' } }}
                                >
                                    Grant one-time admin consent to connect your planmanagement with SharePoint. Your Azure Account needs one of the following roles: Global Administrator, Privileged Role Administrator, Cloud Application Administrator or Application Administrator.
                                    <Link href={`https://login.microsoftonline.com/${accounts[0]?.tenantId}/adminconsent?client_id=${process.env["REACT_APP_AAD_APP_CLIENT_ID"]}&scope=Sites.ReadWrite.All&redirect_uri=${process.env["REACT_APP_AAD_APP_REDIRECT_URI"]}&state={3}`} target="_blank" underline>
                                        Click to grant admin consent
                                    </Link>
                                </MessageBar> */}
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <PeoplePicker/>
            {/* <Button  tinted onClick={signIn}>Setup Plan</Button> */}
            {/* <Button content="Run Permission Report" primary />
                <Button content="Check Settings" primary />
                <Button content="Setup CDE" primary />
                <Button variant="primary" onClick={RequestPermissisons}> Request additional permissions</Button> */}
        </Flex>
    );
};


function RemoveLastTwoDirectories(the_url) {
    var the_arr = the_url.split('/');
    the_arr.pop();
    the_arr.pop();
    return (the_arr.join('/'));
}

function getViewUrl(item, webUrl) {
    var eTag = item.eTag.replaceAll("'", "").replaceAll('"', '');;
    var DOCUMENT_GUID = eTag.split(",")[0]
    console.log(item.webUrl.split("/"))
    var url = webUrl + "/_layouts/15/Doc.aspx?sourcedoc={" + DOCUMENT_GUID + "}"
    return url
}

function getJsonViewUrl(item, webUrl) {
    var webUrlWithoutHttps = item.webUrl.replace('https://', '');
    var webUrlWithoutDomain = webUrlWithoutHttps.substring(webUrlWithoutHttps.indexOf("/"));
    var webUrlWithoutDomainParent = webUrlWithoutDomain.substr(0, webUrlWithoutDomain.lastIndexOf("/"));
    var url = webUrl + "/settings?id=" + webUrlWithoutDomain + "&parent=" + webUrlWithoutDomainParent + "&p=5"
    console.log(webUrl, webUrlWithoutHttps, webUrlWithoutDomain, url)
    return url
}
