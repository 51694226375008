import React, { useState, useEffect, useMemo, useRef } from "react";
import { TextField } from '@fluentui/react/lib/TextField';
import { Announced } from '@fluentui/react/lib/Announced';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { TooltipHost } from '@fluentui/react';
import { initializeIcons } from "@fluentui/react/lib/Icons";
import moment from 'moment'
import { TeamDataPanel } from "./TeamDataPanel";
import { Input } from '@fluentui/react-northstar'
import { Button } from '@fluentui/react-northstar'
import { Avatar } from '@fluentui/react-northstar'
import {
  TeamsIcon,
  ContactGroupIcon,
  VolumeUpIcon,
  SettingsIcon,
  CheckmarkCircleIcon,
  TenantPersonalIcon,
  TeamsMonochromeIcon,
  FilesPdfIcon,
  ShieldKeyholeIcon
} from '@fluentui/react-icons-northstar'
import { Icon } from '@fluentui/react/lib/Icon';
import { Menu, menuAsToolbarBehavior, Tooltip } from '@fluentui/react-northstar'

// Initialize icons in case this example uses them
initializeIcons();

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
});

const controlStyles = {
  margin: '10px 10px 20px 10px'
};

var _selection;

const itemRenderer = (MenuItem, props) => {
  const { tooltip = '', key, ...rest } = props
  return (
    <Tooltip content={tooltip} key={key}>
      <MenuItem {...rest} />
    </Tooltip>
  )
}

const menuItemsSites = [
  {
    key: 'ALL',
    content: 'Alle Seiten'
  },
  {
    key: 'divider-1',
    kind: 'divider',
  },
  {
    key: 'GROUP',
    icon: <ContactGroupIcon styles={{ color: '#EC3C01' }} />,
    tooltip: 'SharePoint Teamsite mit Microsoft 365 Gruppe',
    children: itemRenderer
  },
  {
    key: 'divider-2',
    kind: 'divider',
  },
  {
    key: 'TEAMCHANNEL',
    icon: <TeamsIcon />,
    tooltip: 'SharePoint Seite für einen Kanal in Teams',
    children: itemRenderer
  },
  {
    key: 'divider-3',
    kind: 'divider',
  },
  {
    key: 'SITEPAGEPUBLISHING',
    icon: <VolumeUpIcon styles={{ color: '#03868A' }} />,
    tooltip: 'SharePoint Kommunikationssite ohne Microsoft 365 Gruppe',
    children: itemRenderer
  }
]

const menuItemsProjects = [
  {
    key: 'active',
    content: 'Aktive Projekte',
    tooltip: 'Aktive Projekte',
    icon: <TenantPersonalIcon styles={{ color: '#0779FF' }} outline />,
    children: itemRenderer
  },
  {
    key: 'inactive',
    content: 'Nicht verwaltete Projekte',
    tooltip: 'Nicht verwaltete Projekte',
    children: itemRenderer
  }
]

var _allItems = []

export const TeamsData = (props) => {


  const [items, setItems] = useState(_allItems);
  const [isModalSelection, setIsModalSelection] = useState(false);
  const [isCompactMode, setIsCompactMode] = useState(false);
  const [announcedMessage, setAnnouncedMessage] = useState(undefined);
  const [teamData, setTeamData] = useState(null);
  const [menuActiveIndex, setMenuActiveIndex] = useState(-1);

  _allItems = React.useMemo(() => {
    return _generateDocuments(props.TeamsData.value)
  }, [items]);

  useEffect(() => {
    const newItems = _copyAndSort(_generateDocuments(props.TeamsData.value), "dateModifiedValue", true);
    setItems(newItems)
  }, [props]);

  const _onColumnClick = (ev, column) => {
    const newColumns = columns.slice();
    const currColumn = newColumns.filter(currCol => column?.key === currCol?.key)[0];
    newColumns.forEach((newCol) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn?.fieldName, currColumn?.isSortedDescending);
    setColumns(newColumns);
    setItems(newItems);
  };

  _selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          setSelectionDetails(_getSelectionDetails());
        },
      }),
    []);

  const columnsItems = [
    {
      key: 'column1',
      name: 'File Type',
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      ariaLabel: 'Column operations for File type, Press to sort on File type',
      iconName: 'Page',
      isIconOnly: true,
      fieldName: 'fileType',
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      minWidth: 16,
      maxWidth: 16,
      onColumnClick: (e, v) => _onColumnClick(e, v),
      onRender: (item) => (
        <TooltipHost content={item.fileType}>
          {item.fileType == "TEAM" && <TeamsIcon  />}
          {item.fileType == "TEAMCHANNEL" && <TeamsMonochromeIcon outline styles={{ color: '#5059C9' }} />}
          {item.fileType == "GROUP" && <ContactGroupIcon styles={{ color: '#EC3C01' }} />}
          {item.fileType == "SITEPAGEPUBLISHING" && <VolumeUpIcon styles={{ color: '#03868A' }} />}
        </TooltipHost>
      ),
    },
    {
      key: 'column2',
      name: 'Name',
      fieldName: 'name',
      minWidth: 210,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: (e, v) => _onColumnClick(e, v),
      data: 'string',
      isPadded: true,
      onRender: (item) => {
        return <Button
          size="small"
          text content={item.name}
          onClick={() => setTeamData(item)}
        />
      },
    },
    {
      key: 'column6',
      name: 'Plans',
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      ariaLabel: '',
      isIconOnly: true,
      fieldName: 'plans',
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      minWidth: 16,
      maxWidth: 16,
      onColumnClick: (e, v) => _onColumnClick(e, v),
      onRender: (item) => (
        <TooltipHost content="Planmanagement">
          {item.plans && <FilesPdfIcon />}
        </TooltipHost>
      ),
    },
    {
      key: 'column5',
      name: 'Permissions',
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      ariaLabel: '',
      isIconOnly: true,
      fieldName: 'permissions',
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      minWidth: 16,
      maxWidth: 16,
      onColumnClick: (e, v) => _onColumnClick(e, v),
      onRender: (item) => (
        <TooltipHost content="Berechtigungen">
          {item.permissions && <ShieldKeyholeIcon outline />}
        </TooltipHost>
      ),
    },
    {
      key: 'column3',
      name: 'Geändert',
      fieldName: 'dateModifiedValue',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: (e, v) => _onColumnClick(e, v),
      data: 'number',
      onRender: (item) => {
        return <span>{item.dateModified}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Erstellt',
      fieldName: 'dateCreatedValue',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: (e, v) => _onColumnClick(e, v),
      data: 'number',
      onRender: (item) => {
        return <span>{item.dateCreated}</span>;
      },
      isPadded: true,
    }
    /* 
    {
      key: 'column4',
      name: 'Modified By',
      fieldName: 'modifiedBy',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      onColumnClick: (e,v) => _onColumnClick(e, v),
      onRender: (item) => {
        return <span>{item.modifiedBy}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'File Size',
      fieldName: 'fileSizeRaw',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      isCollapsible: true,
      data: 'number',
      onColumnClick: (e,v) => _onColumnClick(e, v),
      onRender: (item) => {
        return <span>{item.fileSize}</span>;
      },
    },*/
  ];

  const [columns, setColumns] = useState(columnsItems);


  useEffect(() => {
    setColumns(columnsItems)
  }, [items]);

  const [selectionDetails, setSelectionDetails] = useState(_getSelectionDetails());

  const _onChangeText = (ev, text) => {
    setMenuActiveIndex(-1)
    var filtered = text ? _allItems.filter(i => i?.name?.toLowerCase().indexOf(text.value) > -1) : _allItems;
    setItems(filtered);
  };

  const _onChangeSites = (event, item) => {
    var filtered = item?.index ? _allItems.filter(i => i?.WebTemplate == menuItemsSites[item.index].key) : _allItems;
    setItems(filtered);
  };

  const _onChangeProjects = (event, item) => {
    setMenuActiveIndex(item?.index)
    var filtered = _allItems.filter(i => {
      if (menuItemsProjects[item.index].key == 'active') return i.settings
      if (menuItemsProjects[item.index].key == 'inactive') return !Boolean(i.settings)
    });
    setItems(filtered);
  };

  return (
    <div>
      <div className={classNames.controlWrapper}>
        <Input fluid placeholder="Suche ..." onChange={(ev, item) => _onChangeText(ev, item)} styles={controlStyles} />
        <Menu
          fluid
          styles={{ margin: 'auto 0', border: 'None', margin: '10px', background: '#fafafa' }}
          fill
          defaultActiveIndex={menuActiveIndex}
          activeIndex={menuActiveIndex}
          accessibility={menuAsToolbarBehavior}
          onItemClick={(ev, txt) => _onChangeProjects(ev, txt)}
          items={menuItemsProjects} />
        {/* <Menu
          styles={{ margin: 'auto 0', border: 'None', margin: '10px', background: '#fafafa' }}
          accessibility={menuAsToolbarBehavior}
          onItemClick={(ev, txt) => _onChangeSites(ev, txt)}
          defaultActiveIndex={0}
          items={menuItemsSites} /> */}
        <Announced message={`Number of items after filter applied: ${items?.length}.`} />
        <Announced message={selectionDetails} />
      </div>
      {/*       <MarqueeSelection selection={_selection}>
        <DetailsList
          items={items}
          compact={false}
          columns={columns}
          selectionMode={SelectionMode.multiple}
          getKey={_getKey}
          setKey="multiple"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selection={_selection}
          selectionPreservedOnEmptyClick={true}
          onItemInvoked={_onItemInvoked}
          enterModalSelectionOnTouch={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="select row"
        />
      </MarqueeSelection> */}
      <DetailsList
        items={items}
        compact={isCompactMode}
        columns={columns}
        selectionMode={SelectionMode.none}
        getKey={_getKey}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        onItemInvoked={_onItemInvoked}
      />
      {teamData && <TeamDataPanel data={teamData} />}

    </div>
  );

}
function componentDidUpdate(previousProps, previousState) {
  if (previousState.isModalSelection !== state.isModalSelection && !state.isModalSelection) {
    _selection.setAllSelected(false);
  }
}

function _getKey(item, index) {
  return item.key;
}

function _onItemInvoked(item) {
  alert(`Item invoked: ${item.name}`);
}

function _getSelectionDetails() {
  const selectionCount = _selection.getSelectedCount();
  switch (selectionCount) {
    case 0:
      return 'No items selected';
    case 1:
      return '1 item selected: ' + (_selection.getSelection()[0]).name;
    default:
      return `${selectionCount} items selected`;
  }
}

function _copyAndSort(items, columnKey, isSortedDescending) {
  const key = columnKey;
  return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function _generateDocuments(files) {
  const items = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const randomFileSize = 21;
    const dateModifiedValue = file.createdDateTime > file.lastModifiedDateTime ? file.createdDateTime : file.lastModifiedDateTime;
    let siteItem = {
      key: i.toString(),
      name: file.name,
      value: file.name,
      iconName: _getFileIcon(file.WebTemplate),
      fileType: file.team ? 'TEAM' : file.WebTemplate,
      modifiedBy: moment(file.lastModifiedDateTime).format('DD.MM.YYYY'),
      dateModified: moment(dateModifiedValue).format('DD.MM.YYYY'),
      dateModifiedValue: dateModifiedValue,
      dateCreated: moment(file.createdDateTime).format('DD.MM.YYYY'),
      dateCreatedValue: file.createdDateTime,
      fileSize: randomFileSize,
      fileSizeRaw: randomFileSize,
    }
    siteItem = { ...siteItem, ...file };
    items.push(siteItem);
  }
  return items;
}

function _getFileIcon(type) {
  let docType = "spreadsheet";
  if (type == "TEAMCHANNEL") docType = "pub"
  return `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${docType}.svg`;
}

