import React, { useState, useEffect } from 'react';
import { PeoplePicker, People } from '@microsoft/mgt-react';


function App() {
    const [people, setPeople] = useState([]);

    const handleSelectionChanged = (e) => {
        setPeople(e.target.selectedPeople);
    };

    return (
        <div>
            <PeoplePicker userType="user" selectionChanged={handleSelectionChanged} />
            Selected People: <People people={people} />
        </div>
    );
}

export default App;