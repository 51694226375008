import React, { useState, useEffect } from "react";
import { Panel, PanelType, Stack } from '@fluentui/react';
import { TeamData } from "./TeamData";

export const TeamDataPanel = (props) => {
    const [isOpen, openPanel] = useState(false);
    useEffect(() => {
        if (props.data) {
            openPanel(true)
        }
    }, [props.data]);

    return (
        <Panel
            isLightDismiss
            type={PanelType.medium}
            isOpen={isOpen}
            layerProps={{ styles: { root: { zIndex: 100 } } }}
            overlayProps={{ styles: { root: { zIndex: 100 } } }}
            onDismiss={() => openPanel(false)}
            isBlocking={false}
            closeButtonAriaLabel="Close"
        >
            {props.data && <TeamData data={props.data} />}
        </Panel >
    );
};