import React, { useState, useEffect } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import {
    loginRequest,
    sitesReadRequestSharePoint,
    sitesRequestGraph,
    sitesRequestListTeams
} from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { ProfileData } from "./components/ProfileData";
import { TeamsData } from "./components/TeamsData";
import { TeamData } from "./components/TeamData";
import {
    callMsGraph,
    callMsGraphTeams,
    callMsGraphSites,
    callGraphSiteSettingsItems,
    sharepointListSites
} from "./graph";
import { ToastContainer } from 'react-toastify';
import "./styles/App.css";
import { Text, Image, Layout, itemLayoutClassName, useContextSelector } from '@fluentui/react-northstar'
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { Card, CardHeader, CardBody, Flex } from '@fluentui/react-northstar'
import jwtDecode from 'jwt-decode';
import { Avatar } from '@fluentui/react-northstar'
import { SignInButton } from "./components/SignInButton";
import { values } from "@fluentui/react";
import { Route, Routes, Link } from "react-router-dom"


initializeIcons();
/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */

let hostname = '';
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [teamsData, setTeamsData] = useState(null);
    const [sharePointData, setSharePointData] = useState(null);

    useEffect(() => {
        RequestTeamsData();
        console.log(accounts[0])
    }, []);

    var requestObjPermissions = {
        scopes: ["Sites.FullControl.All"],
        account: accounts[0]
    };

    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken).then(response => setGraphData(response));
        });

    }

    function RequestTeamsData() {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then(function (response) {
            const claims = jwtDecode(response.accessToken);
            if (!claims.scp.includes("Sites.Read.All")) {
                instance.acquireTokenPopup(sitesReadRequestSharePoint)
                    .then(function (response) {
                        callMsGraphSites(response.accessToken).then(responseSites => { setTeamsData(responseSites); });
                    }).catch(error => {
                        console.log(error);
                    });
            } else {
                
                callMsGraphSites(response.accessToken)
                    .then(responseSites => {
                        setTeamsData(responseSites);
                        if (responseSites?.value?.[0]) hostname = responseSites?.value[0].siteCollection.hostname
                        console.log(hostname)
                        instance.acquireTokenSilent({
                            scopes: ['https://' + hostname + "/.default"],
                            account: accounts[0]
                        })
                            .then(function (tokenResponse) {
                                sharepointListSites('https://' + hostname, tokenResponse.accessToken)
                                    .then(responseSPSites => {
                                        let array = responseSPSites?.d?.query?.PrimaryQueryResult?.RelevantResults?.Table?.Rows?.results;
                                        const map1 = array.map(x => {
                                            let obj = x;
                                            x.WebTemplate = x.Cells.results[32].Value;
                                            return x.Cells.results.reduce((acc, cur) => ({ ...acc, [cur.Key]: cur.Value }), {})
                                        });
                                        setSharePointData(map1)
                                        responseSites?.value.forEach(team => {
                                            map1.forEach(site => {
                                                if (team.webUrl == site.SPWebUrl || team.webUrl == site.Path)
                                                    team.WebTemplate = site.WebTemplate
                                                if (team.webUrl == site.ParentLink)
                                                    team.settings = true
                                                if (team.webUrl == site.SiteName && site.ProgId)
                                                    team.team = true
                                                if (team.webUrl == site.SiteName && site.Title == 'permission_matrix')
                                                    team.permissions = true
                                                if (team.webUrl == site.SiteName && site.Title == 'schema_input')
                                                    team.plans = true
                                            })
                                        });
                                        console.log(map1, responseSites)
                                        setTeamsData(responseSites)
                                    });
                            }).catch(error => {
                                console.log(error);
                            });
                    });
            }
        }).catch(function (error) {
            console.log(error)
        });
    }

    return (
        <div>
            <ToastContainer
                position="bottom-right"
                autoClose={false}
                newestOnTop
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                toastStyle={{ backgroundColor: "transparent" }}
            />
            <Flex padding="padding.medium" gap="gap.small" styles={{ root: { marginLeft: 6 } }}>
                <Avatar
                    name={accounts[0]?.name}
                    status="unknown"
                />
                <Flex column>
                    <Text content={accounts[0]?.name} weight="bold" />
                    <Text content={accounts[0]?.username} size="small" />
                </Flex>
            </Flex>
            <Routes>
                <Route path="/" element={teamsData && <TeamsData TeamsData={teamsData} />} />
                <Route path="/sharepoint" element={<TeamData TeamsData={null} />} />
            </Routes>

        </div>
    );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    return (
        <div className="App" >
            <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Image
                    styles={{
                        height: '400px',
                        objectFit: 'cover',
                        objectPosition: 'top'
                    }}
                    fluid
                    src="/flinker-home.png"
                />
                <Flex hAlign="center" gap="gap.small" padding="padding.medium">
                    <Flex column>
                        <br></br>
                        <Text disabled weight="semibold" size="large" content="Arbeiten Sie in Ihrer vertrauten Umgebung." />
                        <Text weight="bold" size="largest" content="Flinker Zusammenarbeiten​ in Ihrer eigenen Cloudumgebung​." />
                        <br></br>
                        <SignInButton />
                    </Flex>
                </Flex>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
