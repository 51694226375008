import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";
import App from "./App.jsx";
import AppMgt from "./AppMgt.jsx";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { Provider, teamsTheme } from '@fluentui/react-northstar'; //https://fluentsite.z22.web.core.windows.net/quick-start
import { BrowserRouter } from "react-router-dom"


//mgt
// import { Providers, ProviderState } from '@microsoft/mgt-element';
// import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
// Providers.globalProvider = new Msal2Provider({
//   clientId: msalConfig.auth.clientId
// });
// ReactDOM.render(
//   <Provider theme={teamsTheme}>
//       <BrowserRouter>
//         <AppMgt />
//       </BrowserRouter>
//   </Provider>,
//   document.getElementById("root")
// );


/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
ReactDOM.render(
  <Provider theme={teamsTheme}>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MsalProvider>
  </Provider>,
  document.getElementById("root")
);


