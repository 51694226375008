import { graphConfig } from "./authConfig";
import * as React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    MessageBar,
    MessageBarType
} from '@fluentui/react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';

const FUNCTION_URL_PERMISSIONS = process.env.REACT_APP_FUNCTION_URL_PERMISSIONS
const FUNCTION_URL_PLANS = process.env.REACT_APP_FUNCTION_URL_PLANS

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => handleResponse(response))
        .catch(error => setMessage(error));
}
export async function callMsGraphTeams(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(graphConfig.joinedTeams, options)
        .then(response => handleResponse(response))
        .catch(error => setMessage(error));
}
export async function callMsGraphSites(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(graphConfig.sites, options)
        .then(response => handleResponse(response))
        .catch(error => setMessage(error));
}

export async function callSharePointPermissionsChange(siteUrl, accessToken) {
    const params = new URLSearchParams({
        siteUrl: siteUrl
    })
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json;odata=verbose');
    const options = {
        method: "PUT",
        headers: headers
    };
    var title = 'Rechtematrix Änderungen ' + siteUrl.split('/').pop()
    var id = startCall(title)
    try {
        let response = await fetch(FUNCTION_URL_PERMISSIONS + "&" + params, options);
        return handleResponse(response, id, title)
    } catch (error) {
        console.log(error)
        handleError(error, id, title)
    }
}

export async function getSharePointPermissions(siteUrl, accessToken) {
    const params = new URLSearchParams({
        siteUrl: siteUrl
    })
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json;odata=verbose');
    const options = {
        method: "GET",
        headers: headers
    };
    var title = 'Rechtematrix generieren ' + siteUrl.split('/').pop()
    var id = startCall(title)
    try {
        let response = await fetch(FUNCTION_URL_PERMISSIONS + "&" + params, options);
        return handleResponse(response, id, title)
    } catch (error) {
        console.log(error)
        handleError(error, id, title)
    }
}

export async function callSharePointPermisisons(siteUrl, accessToken) {
    const params = new URLSearchParams({
        siteUrl: siteUrl
    })
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json;odata=verbose');
    const options = {
        method: "POST",
        headers: headers
    };
    var title = 'Rechtematrix Update ' + siteUrl.split('/').pop()
    var id = startCall(title)
    try {
        let response = await fetch(FUNCTION_URL_PERMISSIONS + "&" + params, options);
        return handleResponse(response, id, title)
    } catch (error) {
        console.log(error)
        handleError(error, id, title)
    }

    // const promise = fetch(functionUrl + "&" + params, options);
    // handlePromiseWithToast(promise)
}

export async function callSharePointPlans(siteUrl, accessToken, tenantId) {
    const params = new URLSearchParams({
        siteUrl: siteUrl
    })
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json;odata=verbose');
    const options = {
        method: "POST",
        headers: headers
    };
    var title = 'Update Planmanagement ' + siteUrl
    var id = startCall(title)
    try {
        let response = await fetch(FUNCTION_URL_PLANS + "&" + params, options);
        return handleResponse(response, id, title)
    } catch (error) {
        handleError(error, id, title)
        throw error
    }
}

export async function sharepointListSites(url, accessToken) {
    console.log(url)
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json;odata=verbose');
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(url + "/_api/search/query?querytext='(contentclass:STS_Site)+OR+(contentclass:STS_List_DocumentLibrary Title:\"settings\")+OR+(contentclass:STS_ListItem_DocumentLibrary Title:\"General\" ProgId=\"Team.Channel\")+OR+(contentclass:STS_ListItem_DocumentLibrary Title:\"permission_matrix\")+OR+(contentclass:STS_ListItem_DocumentLibrary Title:\"schema_input\")'&rowlimit=5000&TrimDuplicates=false", options)
        .then(response => handleResponse(response))
        .catch(error => setMessage(error));
}

export async function callSharePointSite(url, accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json;odata=verbose');
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(url + "/_api/web", options)
        .then(response => handleResponse(response))
        .catch(error => setMessage(error));
}

export async function callGraphSiteSettingsItems(siteId, accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(`https://graph.microsoft.com/v1.0/sites/${siteId}/lists/settings/items?$expand=versions`, options)
        .then(response => handleResponse(response))
        .catch(error => setMessage(error));
}

export async function callAPI(theUrl, accessToken) {

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200)
            console.log(JSON.parse(this.responseText));
    }
    xmlHttp.open("GET", theUrl, true); // true for asynchronous
    xmlHttp.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xmlHttp.setRequestHeader('Accept', 'application/json;odata=verbose');
    xmlHttp.send();
}


export async function callAPIcreateList(theUrl, accessToken) {

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200)
            console.log(JSON.parse(this.responseText));
    }
    xmlHttp.open("POST", theUrl, true); // true for asynchronous
    xmlHttp.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xmlHttp.setRequestHeader('Accept', 'application/json;odata=verbose');
    xmlHttp.setRequestHeader('Content-Type', 'application/json;odata=verbose')
    xmlHttp.send(JSON.stringify({
        "__metadata": {
            "type": "SP.List"
        },
        "AllowContentTypes": true,
        "BaseTemplate": 101,
        "ContentTypesEnabled": true,
        "Description": "My list description",
        "Title": "Testti"
    }));
}

function setMessage(error) {
    console.log(error)
}

async function handleResponse(response, id, title) {
    if (!response.ok) {
        var parsedJson = await response.text();
        toast.update(id,
            {
                render: <Message title={'Error: ' + title}
                    message={parsedJson}
                    error={true} />,
                type: "error",
                isLoading: false,
                closeButton: true,
                icon: false
            });
        throw parsedJson
    } else {
        toast.update(id,
            {
                render: <Message title={title} success={true} />,
                type: "success",
                isLoading: false,
                closeButton: true,
                icon: false
            });
        return response.json()
    }
}
async function handleError(error, id) {
    toast.update(id, {
        render: <Message />,
        type: "warn",
        isLoading: false
    });
}
function startCall(title) {
    const id = toast.loading(
        <Message loading={true} title={title} />,
        {
            type: "default",
            isLoading: false,
            closeButton: true,
            icon: false
        })
    return id
}


const Message = (props) => {
    return (
        <>
            <MessageBar
                messageBarType={props.error ? MessageBarType.error : props.success ? MessageBarType.success : MessageBarType.info}
                truncated={true}
                overflowButtonAriaLabel="See more"
                styles={{
                    root: {
                        borderRadius: 0
                    }
                }}
            >
                {props.title}<br></br>
                {props.message}

            </MessageBar>
            {props.loading && <ProgressIndicator styles={{ itemProgress: { padding: 0, margin: 0 } }} />}
        </>
    )
};

