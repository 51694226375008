import { Providers, ProviderState } from '@microsoft/mgt-element';
import { Agenda, Login } from '@microsoft/mgt-react';
import React, { useState, useEffect } from 'react';
import { PeoplePicker, People } from '@microsoft/mgt-react';

function useIsSignedIn() {
    const [isSignedIn, setIsSignedIn] = useState(false);

    useEffect(() => {
        const updateState = () => {
            const provider = Providers.globalProvider;
            setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
            providerStateChanged()
        };

        Providers.onProviderUpdated(updateState);
        updateState();

        return () => {
            Providers.removeProviderUpdatedListener(updateState);
        }
    }, []);

    return [isSignedIn];
}

const providerStateChanged = async () => {
    if (Providers.globalProvider.state === ProviderState.SignedIn) {
        const token = await Providers.globalProvider.getAccessToken({ scopes: ["https://graph.microsoft.com/.default"] })
        console.log(token)
    }
}



function App() {
    const [isSignedIn] = useIsSignedIn();
    const [people, setPeople] = useState([]);

    const handleSelectionChanged = (e) => {
        setPeople(e.target.selectedPeople);
    };

    return (
        <div className="App">
            <header>
                <Login />
            </header>
            <div>
                {/* {isSignedIn &&
          <Agenda />} */}
            </div>
            <PeoplePicker userType="user" selectionChanged={handleSelectionChanged} />
            Selected People: <People people={people} />
        </div>
    );
}

export default App;